.table {
  border: 1px solid #e9ecef;
}
.thread,
.table tr {
  border: none;
  border-bottom: 1px solid #e9ecef;
  padding: 5px 0;
}
.thread {
  font-weight: bold;
  text-indent: none !important;
}
.table tbody tr:last-child {
  border-bottom: none;
}

.table tbody tr:nth-child(odd) {
  background-color: #f9fafb;
}

.centerTable tr td {
  text-align: center;
  padding: 5px 0;
}
.leftTable tr td {
  padding-left: 13px;
  text-align: center;
}
