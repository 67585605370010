.user-mng-container {
  width: 100%;
}
.user-mng-padding {
  padding: 30px 32px;
}
.user-mng-width {
  width: 760px;
}
.user-mng-title {
  font-size: 20px;
  font-weight: 900;
}
.user-mng-table {
  margin-bottom: 30px;
}
.user-mng-table:last-child {
  margin-bottom: 0;
}
.user-mng-table-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.user-mng-table-title h2 {
  font-size: 18px;
  font-weight: 900;
}
.user-mng-table-main {
  border: 1px solid #e9ecef;
  width: 100%;
}

.user-mng-pwd-button {
  margin-left: 16px;
}
.user-mng-pwd-button button {
  padding: 3px 10px 2px 10px;
  color: #ffffff;
  border: none;
  border-radius: 3px;
  background-color: #444054;
  cursor: pointer;
}
