.invoice-container {
  margin: auto;
}
.invoice-logo,
.invoice-company-info,
.invoice-date,
.invoice-guide,
.invoice-table {
  margin: auto;
  width: 960px;
}
.invoice-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.invoice-logo {
  margin-top: 50px;
  margin-bottom: 50px;
}
.invoice-company-info {
  margin-bottom: 35px;
}
.invoice-date {
  margin-bottom: 38px;
}
.invoice-date h3 {
  font-size: 18px;
  font-weight: bold;
}
.invoice-guide {
  margin-bottom: 76px;
}
.invoice-guide h2 {
  font-size: 22px;
  font-weight: 900;
}
.invoice-title {
  font-size: 24px;
  font-weight: 900;
}
