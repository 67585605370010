.container {
  display: flex;
}
.td {
  text-align: left;
  padding: 10px 0 10px 15px;
  flex: 1;
}
.td:nth-child(odd) {
  font-weight: bold;
  background-color: #f9fafb;
}
