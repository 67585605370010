.container {
  margin-top: 30px;
  padding-bottom: 20px;
}
.stressFont {
  font-weight: 400;
  color: #6c757d;
}
.stressFont1 {
  font-weight: 400;
  color: #6c757d;
  padding-bottom: 20px;
}
.stressTitleFont {
  font: normal normal bold 18px/30px Noto Sans KR;
  letter-spacing: 0px;
  color: #000;
  font-weight: 800;
}
.insurfeature {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.tableWidth {
  width: 400px;
  height: 156px;
  border: 1px solid #e9ecef;
  margin-top: 26px;
}
.firstCol {
  font: normal normal bold 16px/24px Noto Sans CJK KR;
  background: #f9fafb 0% 0% no-repeat padding-box;
  padding-left: 13px;
}
.secondCol {
  padding-left: 13px;
}
.twoBtn {
  display: flex;
  padding-top: 10px;
}
.twoBtn > button {
  width: 132px;
  height: 33px;
  background: #3ac47d 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  font: normal normal medium 16px/24px Noto Sans CJK KR;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  border: none;
  cursor: pointer;
}
.twoBtn > button:nth-child(2) {
  margin-left: 8px;
}
.mainTitle {
  font: normal normal bold 18px/30px Noto Sans KR;
  letter-spacing: 0px;
  color: #000;
  font-weight: 800;
}
