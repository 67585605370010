.listTableContainer {
  border: 1px solid #e9ecef;
}

.listTableThead {
  border: 1px solid #e9ecef;
}
.listTableTr th {
  font-size: 13px;
  border: none;
  font-weight: bold;
}
.listTableTr th:nth-child(1) {
  width: 200px;
}
.listTableTr th:nth-child(2) {
  width: 130px;
}
.listTableTr th:nth-child(3) {
  width: 80px;
}
.listTableTr th:nth-child(4) {
}
.listTableTr th:nth-child(5) {
}
.listTableTr th:nth-child(6) {
}
.listTableTr th:nth-child(7) {
}
.listTableTr th:nth-child(8) {
}
.listTableTr th:nth-child(9) {
}
.listTableTr th:nth-child(10) {
}
.listTableTr th:nth-child(11) {
}
.listTableTr th:nth-child(12) {
}
.listTableTr th:nth-child(13) {
}
.listTableTbody tr td {
  text-align: center;
  font-size: 13px;
  border-bottom: 1px solid #e9ecef;
}
.listTableTbody tr {
  cursor: pointer;
}
.listTableTbody tr:nth-child(odd) {
  background-color: #f9fafb;
}
