.insurcal-table {
  border: 1px solid #e9ecef;
}
.insurcal-table tr td:nth-child(odd) {
  background-color: #f9fafb;
  font-weight: bold;
}
.insurcal-table tr td {
  padding-left: 13px;
}
