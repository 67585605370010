.table {
  border: 1px solid #e9ecef;
  box-sizing: border-box;
}
.thread,
.table tr {
  border: none;
  border-bottom: 1px solid #e9ecef;
  height: 38px;
  padding: 5px 0;
}
.thread {
  font-weight: bold;
}
.thread:first-child {
  text-align: left;
  padding-left: 18px;
}
.thread:nth-child(2) {
  text-align: center;
  padding-left: 50px;
}
.table tbody tr td {
  padding: 5px 0;
  cursor: pointer;
}
.main-slick-image {
  width: 100%;
  height: 100%;
  background-color: red;
}
.main-slick-image img {
  resize: cover;
}
.table tbody tr td:first-child {
  text-align: left;
  padding-left: 18px;
  width: 20%;
}
.table tbody tr td:nth-child(2) {
  text-align: left;
  width: 50%;
}
.table tbody tr td:last-child {
  text-align: center;
  width: 30%;
}

.table tbody tr:nth-child(odd) {
  background-color: #f9fafb;
}
