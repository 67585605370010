.warranty-title {
  border-bottom: 1px solid #e9ecef;
  display: flex;
  padding: 10px 0;
  text-align: center;
  margin: 0;
}
.warranty-title li {
  font-weight: bold;
}
.warranty-title li:nth-child(1),
.warranty-body-title {
  flex: 2;
}

.warranty-title li:nth-child(2),
.warranty-body-content-name {
  flex: 5;
}
.warranty-title li:nth-child(3),
.warranty-body-content-price {
  flex: 3;
}
.warranty-body {
  display: flex;
  margin: 0;
  border-bottom: 1px solid #e9ecef;
}
.warranty-body:last-child {
  border-bottom: 0;
}
.warranty-body-title {
  font-weight: bold;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.warranty-body-content {
  flex: 8;
}
.warranty-body-content > div:nth-child(odd) {
  background-color: #f9fafb;
}
.warranty-body-content div {
  display: flex;
}
.warranty-body-content div div {
  padding: 10px 5px 10px 13px;
  display: flex;
  align-items: center;
}
