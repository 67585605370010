body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "gmarketSansBold";
  src: url("./assets/font/GmarketSansTTFBold.ttf");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "gmarketSansLight";
  src: url("./assets/font/GmarketSansTTFLight.ttf");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "gmarketSansMedium";
  src: url("./assets/font/GmarketSansTTFMedium.ttf");
  font-style: normal;
  font-weight: 500;
}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
