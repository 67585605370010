.container {
  width: 100vw;
  height: inherit;
}
.padding {
  padding: 30px;
}

.mainContainer {
  margin-bottom: 30px;
  border: 1px solid #e9ecef;
}
.titleArea {
  display: flex;
  align-items: center;
  height: 50px;
  font-weight: bold;
  padding-left: 15px;
  padding-top: 5px;
  border-bottom: 2px solid #e9ecef;
}
.title {
  font-size: 16px;
  font-weight: bold;
}
.contentArea {
  padding: 22.5px 15px;
}
.content {
  padding: 22.5px 15px;
  background-color: #ffffff;
  color: #888888;
  font-size: 14px;
  width: 100%;
  display: inline-block;
  border: none;
  height: 638px;
}
.buttonWrapper {
  width: 100%;
  display: flex;
  justify-content: right;
}
.button {
  height: 33px;
  width: 132px;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  cursor: pointer;
}
