.sidebar {
  background-color: rgb(251, 251, 255);
  position: sticky;
  top: 0px;
}
.submenu {
  background-color: red;
  height: 20px;
  margin-top: 10px;
}

.submenu-text,
.submenu-untext {
  font-family: "Noto Sans KR";
  color: #6c757d;
}
.submenu-text:hover {
  /* background-color: #e0f3ff; */
  color: #545cd8;
}
.pro-sidebar .pro-menu a {
  color: #545cd8 !important;
  font-family: "Noto Sans KR,Medium";
  font-weight: bold;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
  background-color: #fff;
  color: #545cd8;
  font-family: "Noto Sans KR,Medium";
  font-weight: bold;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  background-color: #fff !important;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding-top: 0px !important;
}
