.modalHeader {
  background-color: #f8f9fa;
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
  height: 65px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.modalHeader h2 {
  margin: 0;
  font-size: 24px;
  font-weight: 900;
}
.modalHeader button {
  border: 1px solid #dcdcdc;
  padding: 2px 8px;
  border-radius: 3px;
  cursor: pointer;
  background-color: #eeeeee;
}
.modalMain {
  padding-left: 30px;
  padding-right: 30px;
}
.modalTable {
  margin-top: 30px;
  border: 1px solid #e9ecef;
}
.modalExtra {
  margin-top: 20px;
}
.modalInput {
  border: 1px solid #ced4da;
  width: 100%;
}
.modalExtra div {
  color: #6c757d;
  margin-bottom: 5px;
}
.modalBottom {
  position: absolute;
  bottom: 0;
  background-color: #f8f9fa;
  align-items: center;
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: center;
}
.modalBottom button {
  background-color: #545cd8;
  font-size: 18px;
  color: #ffffff;
  border: none;
  border-radius: 3px;
  width: 300px;
  height: 45px;
  cursor: pointer;
}
.modalError {
  margin-top: 10px;
  text-align: center;
  width: 100%;
}
.error {
  color: #d92550;
}
