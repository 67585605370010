.certi-container {
  width: inherit;
  height: inherit;
}
.certi-title {
  font-size: 24px;
  font-weight: 900;
}
.certi-inner {
  margin: 0px;
  border: 1px solid #000;
  padding: 40px 20px;
}
.certi-pdf-btn {
  float: right;
  margin-right: 50px;
}
.certi-pdf-btn button {
  background-color: #6c757d;
  border-radius: 3px;
  border: none;
  height: 33px;
  width: 136px;
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
}
.certi-header {
  margin-bottom: 50px;
}
.certi-header h3 {
  font-size: 24px;
  font-weight: 900;
}
.certi-table {
  width: 100%;
  margin-bottom: 45px;
}
.certi-table-content {
  border: 1px solid #e9ecef;
}
.certi-table-row {
  width: 100%;
  overflow: hidden;
}
.certi-table-title {
  display: flex;
  justify-content: space-between;
}
.certi-table-title h4 {
  font-size: 18px;
  font-weight: 900;
}
@media print {
  .certi-pdf-btn button {
    display: none !important;
  }
  .warranty {
    margin-top: 40px;
    page-break-before: always;
  }
}
