.container {
  margin-top: 30px;
}
.insur_content_btn_container {
  margin-top: 37px;
}
.insur_content_btn {
  margin-left: 30px;
  width: 100px;
  border-radius: 10px;
  border: 1px solid #d3d3d3;
}
