.notice-container {
  width: 100%;
  height: 100%;
}
.notice-padding {
  padding: 30px;
}
.notice-title-search {
  width: 260px;
  height: 38px;
  border: 1px solid hsl(210, 14%, 83%);
  font-size: 14px;
  border-radius: 3px;
  display: flex;
  text-align: center;
}
.notice-title-search p,
.notice-title-search input,
.notice-title-search button {
  font-size: inherit;
  border: none;
  height: 100%;
}
.notice-title-search input {
  width: 100%;
  padding-left: 12px;
  border-left: 1px solid hsl(210, 14%, 83%);
  border-right: 1px solid hsl(210, 14%, 83%);
}
.notice-title-search span,
.notice-title-search button {
  display: inline-block;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notice-title-search-title {
  background-color: #f9fafb;
  width: 48px;
}
.notice-title-search button {
  background-color: #ecf6fc;
  width: 42px;
  cursor: pointer;
}
