.form-container {
  /* margin: 0 30px; */
  background-color: #f1f4f6;
}
.insur-product-container {
  width: 100%;
  height: 100%;
}

.insur-product-background {
  padding: 30px;
  background-color: #f1f4f6;
  height: 100%;
  width: 100%;
}
.form-sub-container {
}
.insur-product-step-inner {
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 0px 0px 5px #00000024;
  border-radius: 3px;
  margin-bottom: 30px;
}
.bigHeadText-container {
  background: #f9fafb;
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  margin: 0 -30px;
}
.bigHeadText {
  font-size: 24px;
  font-weight: bold;
  margin-left: 30px;
}
.form-top {
  background-color: #fff;
  height: 247px;
  border-radius: 3px;
  box-shadow: 0px 0px 5px #00000024;
  margin-top: 30px;
}
.form-second {
  background-color: #fff;
  min-height: 289px;
  margin-top: 30px;
  border-radius: 3px;
  box-shadow: 0px 0px 5px #00000024;
  padding-bottom: 50px;
}
.form-top .form-top-sub {
  /* margin: 9px 31px 1px; */
  /* margin-left: 31px;
  margin-right: 31px; */
  margin: 0 31px;
}
.form-top .form-top-sub .headText {
  padding-top: 27px;
}
.form-top-sub .headText {
  padding-top: 27px;
  /* margin: 0 31px; */
}
.form-thrid-sub > .headText {
  margin: 0 31px;
  padding: 27px 0 18px;
}
