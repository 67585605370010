.container {
  border: 1px solid #e9ecef;
}
.tableContainer {
}
.tableTitle,
.tableBody {
}
.tableTitle td,
.tableBody td {
  text-indent: 13px;
}
.tableTitle td:nth-child(odd),
.tableBody td:nth-child(odd) {
  font-weight: bold;
  background-color: #f9fafb;
}
