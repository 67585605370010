.button-format {
  display: flex;
  justify-content: center;
}
.button-format button {
  width: 300px;
  height: 45px;
  border-radius: 10px;
  border: 1px solid darkgray;
  background: #545cd8 0% 0% no-repeat padding-box;
  border: 1px solid #ced4da;
  border-radius: 3px;
  opacity: 1;
  font: normal normal medium 18px/15px Noto Sans CJK KR;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
