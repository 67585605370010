.container {
}
.container > td {
  text-align: left;
  padding: 4.5px 15px 4.5px 15px;
}
.container td:first-child {
  font-weight: bold;
  background-color: #f9fafb;
}
