.container {
  width: 100%;
  height: 100%;
}
.titleContainer {
  background-color: #f8f9fa;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  height: 63px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.titleContainer h2 {
  margin: 0;
  font-size: 24px;
  font-weight: 900;
}
.titleContainer button {
  border: 1px solid #dcdcdc;
  padding: 2px 8px;
  border-radius: 3px;
  cursor: pointer;
  background-color: #eeeeee;
}
.inner {
  background-color: #ffffff;
  padding: 20px;
}
.title {
  font-size: 18px;
  font-weight: 900;
}
.description {
  margin-top: 12px;
}
.tableContainer {
  margin-top: 30px;
}
.tableContainer tr td,
.tableContainer th {
  border: 1px solid #e9ecef;
}
.tableTitle {
  font-weight: bold;
}
.tableSelect {
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
}
.tableContentCenter {
  text-align: center;
  vertical-align: middle;
}
.allSelectBtn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
}
.allSelectBtn button {
  height: 33px;
  width: 132px;
  color: #fff;
  background: #6c757d 0% 0% no-repeat padding-box;
  border-radius: 3px;
  font-weight: bold;
}
.bottomBtn {
  display: flex;
  justify-content: center;
  width: 100%;
}
.bottomBtn button {
  height: 45px;
  width: 300px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 3px;
  background: #545cd8;
  color: #fff;
}
.agreementBtn {
  padding: 4px 12px;
  background-color: #eeeeee;
  border-radius: 3px;
}
.tableMainText {
  font-weight: bold;
  padding-left: 13px;
  padding-right: 13px;
}
.tableMainDescription {
  padding-left: 13px;
  font-weight: normal;
  padding-right: 13px;
}
.tableSection {
  margin-top: 30px;
}
.successInner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.successInner h1,
.successInner div {
  margin-top: 150px;
  flex: 1;
}
