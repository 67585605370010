.detail-info-table {
  border: 1px solid #e9ecef;
}
.detail-info-table tr td {
  padding-left: 12px;
}
.detail-info-thread {
  font-weight: bold;
  background-color: #f9fafb;
}
