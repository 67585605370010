.InsuredPeopleInfo {
  display: flex;
  justify-content: center;
}
.InsuredPeopleInfo > table,
tr,
td,
th {
}
.InsuredPeopleInfo-btn {
  display: inline-block;
  float: right;
  margin-top: 12px;
}
.InsuredPeopleInfo-btn > button {
  width: 132px;
  height: 33px;
  background: #3ac47d 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  border-color: #3ac47d;
  color: #fff;
}
.InsuredPeopleInfo-btn > button:nth-child(1) {
  margin-right: 8px;
}
.InsuredPeopleInfo-purchase-info {
  margin-top: 50px;
}
.InsuredPeopleInfo-purchase-info > h3 {
  color: var(---495057-_text-color);
  text-align: left;
  font: normal normal 900 18px/26px Noto Sans KR;
  letter-spacing: 0px;
  color: #495057;
  opacity: 1;
}
.InsuredPeopleInfo-purchase-total-price {
  font-size: 24px;
  font-weight: bold;
}
