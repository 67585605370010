.constract-container {
  height: 100%;
  width: 100%;
}
.constract-background {
  padding: 30px;
  background-color: #f1f4f6;
  height: 100%;
}
.constract-inner {
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 0px 0px 5px #00000024;
  border-radius: 3px;
  margin-bottom: 30px;
}
.constract-title {
  font-weight: 900;
  font-size: 18px;
}
.btn-save {
  margin-top: 8px;
}
.btn-cancel {
  cursor: pointer;
  background-color: #444054;
  color: white;
  border-radius: 3px;
  padding: 5px 36px;
  margin: 0;
}

.constract-btn-wrapper {
  display: flex;
  justify-content: flex-end;
}
.constract-btn-wrapper button {
  cursor: pointer;
  background-color: #444054;
  color: white;
  border-radius: 3px;
  padding: 5px 36px;
  margin-left: 10px;
}
