.insur-form-table {
  width: 100%;
  margin-top: 16px;
  border: 1px solid #e9ecef;
}

.insur-form-table-inner-first {
  background-color: #f9fafb;
  width: 20%;
  padding-left: 20px;
  line-height: 2;
}
.insur-form-table-inner-second {
  text-align: center;
  line-height: 2;
}
.title-text {
  font-weight: bold;
}
