.loading {
  width: "100%";
  height: 100vh;
  opacity: 0.5;
}
.loading-shape {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
