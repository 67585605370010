.payment-container {
  margin-top: 20px;
}
.payment-btn-selected {
  background-color: #444054;
  color: #ffffff;
  padding: 4px 12px;
  border-radius: 3px;
  border: none;
}
.payment-btn-unselected {
  background-color: #f7f7f7;
  padding: 4px 12px;
  border: none;
  border-radius: 3px;
}

.paymentform-container {
  margin-top: 30px;
  margin-bottom: 30px;
}
.paymentform-table {
  width: 750px;
  border: 1px solid #e9ecef;
}
.paymentform-table thead tr th {
  background-color: #f9fafb;
  text-align: left;
  font-weight: bold;
}

.table-two-col-container td {
  text-align: left;
}
.table-two-col-container td:first-child {
  font-weight: bold;
  background-color: #f9fafb;
}
