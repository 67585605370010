.topbar {
  width: 100%;
  height: 60px;
  background-color: white;
  top: 0;
  padding-top: 10px;
  z-index: 999;
  box-shadow: 0px 2px 10px #0000001c;
}

.topbarWrapper {
  height: 100%;
  padding: 10px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topLeft {
  display: flex;
  font-size: 20px;
  align-items: flex-end;
}
.topRight {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.topbarIconContainer {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  color: #888888;
  font-size: 14px;
}

.topIconBadge {
  width: 15px;
  height: 15px;
  position: absolute;
  top: -5px;
  right: 0px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.topAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.logout-btn {
  background-color: #dcdcdc;
  border-radius: 3px;
  padding: 4px 12px;
  font-size: 14px;
}

.logo {
  height: 35px;
}
