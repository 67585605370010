.main-container {
  padding: 30px;
  background-color: #f1f4f6;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.main-slider {
  height: 350px;
  width: 100%;
}
.main-contents {
  height: 100%;
  width: 100%;
  box-shadow: 3px 3px 10px #00000029;
  background: hsl(0, 0%, 100%);
  border-radius: 3px;
  overflow: hidden;
}
.main-banner {
  background-color: #ff6f64;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.main-banner > div {
  display: flex;
}
.main-banner-title {
  flex: 2;
  justify-content: center;
  font-size: 20px;
  align-items: center;
}
.main-banner-title h2 {
  color: white;
  font-weight: bold;
}
.main-banner-flex {
  flex: 3;
  width: 100%;
  justify-content: space-between;
}
.main-banner div div {
  flex: 1;
  text-align: center;
}
.main-banner-img-wrapper {
  height: 90px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-banner img {
  height: 80px;
}
.main-banner h6 {
  color: white;
  font-size: calc(16px * 1.2);
  font-weight: 600;
  margin-top: 15px;
}
.main-content-title {
  font-size: 18px;
  font-weight: 900;
  padding: 16px 20px 14px;
  border-bottom: 1px solid #e2e3f0;
}
.main-notice-explain {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.main-under {
  display: flex;
  height: 528px;
  margin-top: 30px;
}
.main-notice {
  flex: 1.4;
  padding-right: 30px;
}
.main-question {
  flex: 1;
}
.main-slick-image-container {
}
.main-slick-image {
  width: 100%;
  height: 400px;
}
.main-content-inner {
  padding: 20px 20px;
  height: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.main-content-inner::-webkit-scrollbar {
  display: none;
}

.main-notice-content {
  cursor: pointer;
  width: 100%;
  background-color: #f9fafb;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 24px;
  height: 128px;
}
.main-notice-explain {
  max-lines: 2;
}
.main-notice-title {
  display: flex;
  justify-content: space-between;
}
.main-notice-title h4 {
  font-weight: bold;
  font-size: 18px;
}
.main-notice-title span {
  font-size: 14px;
}
.main-question-content {
  margin-bottom: 54px;
}
.main-question-content h4,
.main-question-content a {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
}
.main-question-content div {
  font-size: 18px;
  margin-bottom: 16px;
}
@media (min-width: 1280px) and (max-width: 1500px) {
  .main-container {
    width: 90%;
  }
}
