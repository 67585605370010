.insurPeople-form-table-container {
}
.insurPeople-form-table {
  margin: 14px 0;
  border-collapse: collapse;
}
.insurPeople-form-table tr {
  border: 1px solid #e9ecef;
}

.insurPeople-form-table tr th {
  border-top: 1px solid #e9ecef;
  background-color: #fff;
  vertical-align: middle;
}
.insurPeople-form-table tr:nth-child(odd) {
  background: #f9fafb 0% 0% no-repeat padding-box;
}
.insurPeople-form-table tr td:nth-child(odd) {
  text-align: center;
  vertical-align: middle;
}
.insurPeople-form-table-btn {
  /* margin-left: 54vw; */
}
.insurPeople-form-table-btn button {
  margin-left: 30px;
}
.input-file-button {
  padding: 6px 20px;
  background-color: #3ac47d;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  border: none;
}
.input-file-button:nth-child(1) {
  margin-right: 20px;
}
.add-btn {
  display: flex;
  justify-content: right;
}
.add-btn > button {
  width: 132px;
  height: 29px;
  background: #444054 0% 0% no-repeat padding-box;
  color: #fff;
  border-radius: 3px;
}
.tableDataList-row {
  position: relative;
}
.insurPeople-form-table-th {
  border-right: 1px solid #fff;
}
.insur-cal-button {
  margin-bottom: 30px;
}
