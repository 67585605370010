.user-list-container {
  flex: 2;
  background-color: #fff;
  width: 80%;
}
.user-list-background {
  padding: 30px;
  background-color: #f1f4f6;
  height: 100%;
}
.user-list-inner {
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 0px 0px 5px #00000024;
  border-radius: 3px;
  margin-bottom: 30px;
}
.user-list-table {
  border: 1px solid #e9ecef;
}
.user-list-table tr {
}
.user-list-table tr td {
  padding-left: 13px;
  padding-right: 13px;
}
.user-list-header-table {
}
.user-list-header-table td {
}
.user-list-button {
  display: inline-block;
  text-align: right;
  width: 40%;
}
.user-list-button button {
  cursor: pointer;
  background-color: #444054;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 4px 20px 2px;
  margin: 0;
}

.user-list-table tr td:nth-child(odd) {
  background-color: #f9fafb;
  font-weight: bold;
}
.user-list-input-button {
  width: inherit;
}
.userListUser {
  display: flex;
  align-items: center;
}
.userListImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.userListEdit {
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #3bb077;
  color: white;
  cursor: pointer;
  margin-right: 20px;
}

.userListDelete {
  color: red;
  cursor: pointer;
}

.components-table-demo-nested {
  height: "100%";
}
.ant-pagination-options {
  display: none !important;
}
