.background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: #a9a9a9 0% 0% no-repeat padding-box;
}
.form {
  width: 550px;
  height: 750px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 25px 75px 0px 75px;
}
.form-logo {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 25px;
}
.signin-form-title {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.signin-form-title h2 {
  font-size: 24px;
  font-weight: 900;
}
.signin-form-divider {
  border: none;
  width: 60px;
  background-color: #ff6f64;
  height: 2px;
}
.form-subtitle {
  margin-top: 15px;
  font-size: 14px;
  color: #3c4b64;
  font-family: "gmarketSansMedium";
}
.form-title {
  margin-top: 8px;
  font-weight: 600;
  color: #3c4b64;
  font-size: 35px;
  font-family: "gmarketSansBold";
}
.form-mng-login {
  margin-top: 20px;
  font-weight: 600;
  font-size: 14px;
  font-family: "gmarketSansLight";
}
.form-login-area {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.signin-input {
  margin-top: 13px;
}
.signin-input-title {
  font-size: 16px;
  font-weight: bold;
}
.signin-input-style {
  width: 250px;
  height: 45px;
  border-radius: 8px;
}

.signin-login-btn {
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 8px;
  background-color: #ff6f64;
  color: white;
  height: 50px;
  width: 250px;
}
