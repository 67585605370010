.InsuranceProduct {
  margin-top: 50px;
}
.InsuranceProductList {
  display: flex;
  justify-content: center;
}
.insurance-btn {
  background: #6c757d 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
}
.insurance-purchase-btn {
  background: #444054 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  color: #fff;
}
.modal-exit {
  height: 63px;
  background: #f8f9fa 0% 0% no-repeat padding-box;
}
.modal-exit button {
  float: right;
  margin-top: 19px;
  margin-right: 16px;
  background: #eeeeee 0% 0% no-repeat padding-box;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
}
.insurance-content-container {
  margin: 30px 30px 0;
}
.insurance-content-container-btn {
  width: 132px;
  height: 38px;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 1px solid #e9ecef;
  border-radius: 3px 0px 0px 3px;
  opacity: 1;
  color: #6c757d;
  font: normal normal bold 18px/26px Noto Sans KR;
}
.insurance-content-container-under-btn {
  width: 152px;
  height: 38px;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 1px solid #e9ecef;

  border-radius: 3px 0px 0px 3px;
  opacity: 1;
  color: #fff;
  font: normal normal bold 15px/26px Noto Sans KR;
}

.insurance-content-container-title {
  font: var(--unnamed-font-style-normal) normal 900 var(--unnamed-font-size-24) /
    30px var(--unnamed-font-family-noto-sans-kr);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(---495057-_text-color);
  text-align: left;
  font: normal normal 900 24px/30px Noto Sans KR;
  letter-spacing: 0px;
  color: #495057;
  opacity: 1;
}
