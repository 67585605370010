.background-container {
  padding: 30px;
  background-color: #f1f4f6;
  height: 100%;
}
.background-content {
  background-color: #ffffff;
  box-shadow: 0px 0px 5px #00000024;
  border-radius: 3px;
  opacity: 1;
}
