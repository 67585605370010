.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: #a9a9a9 0% 0% no-repeat padding-box;
  padding-top: 5px;
  padding-bottom: 5px;
}
.form_container {
  width: 550px;
  height: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: scroll;
}
.form_container::-webkit-scrollbar {
  display: none;
}
.form {
  padding: 25px 75px;
}
.form input,
.form select {
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ced4da;
  border-radius: 3px;
  padding-left: 12px;
  padding-right: 12px;
  width: 80%;
}

.form input::placeholder {
  color: #ced4da;
}
.form input::-webkit-outer-spin-button,
.form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.LogoImg {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 25px;
}
.header_title {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 10px;
}
.header_explain {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.formTitle {
  font-size: 24px;
  font-weight: 900;
}
.input {
  margin-top: 13px;
}
.inputTitle {
  font-size: 16px;
  font-weight: bold;
}
.inputstyle {
}
.fileWrapper {
  display: flex;
  align-items: center;
}
.uploadInput {
  margin-right: 10px;
}

.fileButton {
  width: 16%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  background-color: #6c757d;
}
.fileWrapper .bankSelector {
  width: 30%;
  cursor: pointer;
}
.fileWrapper .bankNumber {
  width: 50%;
}
.fileWrapper .purposeSelector {
  width: 80%;
  cursor: pointer;
}
.form_agree {
  margin-top: 13px;
}
.checkbox {
  display: flex;
  align-items: center;
}
.agreeTitle {
  font-size: 14px;
  margin-left: 5px;
  cursor: pointer;
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.agreeMent {
  font-size: 13px;
  color: #6c757d;
}
.form_partner_apply {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.form_partner_apply > button {
  width: 400px;
  height: 45px;
  background: #545cd8 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  color: #ffffff;
  margin-right: 20px;
}
.important {
  color: #d92550;
}
